<template>
    <div class="temp-chat">
        <div class="img-container">
            <img :src="photoUrl" alt="" />
            <div class="start-chat" v-html="`${user.nickname}과 대화를 시작해요`" />
        </div>
        <div class="btn-container">
            <div class="btn btn-primary" @click="onClickOpenChat" v-html="$translate('START_CHAT')" />
            <div class="heart-need" v-html="heartNeedText" />
        </div>
    </div>
</template>

<script>
import chatService from '@/services/chat'

export default {
    name: 'TempChatBody',
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        heartNeed() {
            return this.$store.getters.settings.heart.start_chat
        },
        heartNeedText() {
            return `하트 ${this.heartNeed}개가 사용됩니다`
        },
        user() {
            return this.chat.target_chat_user
        },
        photoUrl() {
            if (this.chat.blocked_id || this.$isDormant(this.user)) return require('@/assets/images/blank_profile.svg')

            const photoUrl = this.user.photo_url
            if (photoUrl) return photoUrl

            return this.$alternativeImg(this.user.gender)
        },
    },
    methods: {
        async onClickOpenChat() {
            const myHeart = (this.$store.getters.badges || {}).heart
            if (myHeart < this.heartNeed) {
                const idx = await this.$modal.basic({
                    body: `하트가 ${this.heartNeed - myHeart}개가 부족합니다. 하트를 충전하고 대화를 시작해보세요!`,
                    buttons: [
                        {
                            label: '취소',
                            class: 'btn-default',
                        },
                        {
                            label: '충전하기',
                            class: 'btn-primary',
                        },
                    ],
                })
                if (idx) this.$stackRouter.push({ name: 'InAppStorePage', props: { from: 'alert' } })
            } else {
                try {
                    const data = await chatService.openChat(this.chat.id)
                    this.chat.opened = true
                    this.$store.dispatch('loadBadges')
                    this.$toast.success(data.msg)
                } catch (e) {
                    this.$toast.error(e.data)
                }
            }
        },
    },
}
</script>

<style scoped lang="scss">
.temp-chat {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .img-container {
        width: 100%;
        flex-direction: column;
        margin-bottom: 48px;
        @include center;

        img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }
        .start-chat {
            margin-top: 16px;
            color: black;
            font-size: 18px;
            @include f-medium;
        }
    }
    .btn-container {
        padding: 0 90px;

        .heart-need {
            @include spoqa-f-medium;
            font-size: 12px;
            color: $grey-07;
            text-align: center;
            margin-top: 10px;
        }
    }
}
</style>
